import React from 'react'
import img1 from "../../../Images/Hosted1.png"
import img2 from "../../../Images/hosted2.jpg"
import img3 from "../../../Images/hosted3.png"
import img4 from "../../../Images/hosted4.png"
import earth from "../../../Images/earth.webp"
import at from "../../../Images/at.webp"

const Hosted = () => {
  return (
    <div>
      <div className="container-fluid">
        <div className="row p-4">
          <div className="col-md-3">
            <div className="hosted_card shadow text-center">
              <img src={img1} className='hosted_card_img' alt='card' width="100%" />
              <div className='hosted_card_body'>
                <p className='hosted_card_title'>Ellis Muiser</p>
                <p>Kitecamp Host</p>
                <p className='hosted_card_year'>2 years kite experience</p>
                <p >I will show you all the hidden gems of Zanzibar! Together we go on kitesurf adventures and amazing parties!</p>
                <p className='hosted_card_last'>
                  <span>
                    <img className='me-2' src={earth} alt="icon" style={{ maxWidth: '25px' }} />
                  </span>
                  <span className='hosted_card_last'><b>Top Destination</b></span>
                </p>
                <p className='hosted_card_last2'>Zanzibar & Waddensea!</p>
                <p className='hosted_card_last'>
                  <span>
                    <img className='me-2' src={at} alt="icon" style={{ maxWidth: '25px' }} />
                  </span>
                  <span><b>Top Destination</b></span>
                </p>
                <p className='hosted_card_last2'>4 years</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="hosted_card shadow text-center">
              <img src={img2} className='hosted_card_img' alt='card' width="100%" />
              <div className='hosted_card_body'>
                <p className='hosted_card_title'>Ellis Muiser</p>
                <p>Kitecamp Host</p>
                <p className='hosted_card_year'>2 years kite experience</p>
                <p >I will show you all the hidden gems of Zanzibar! Together we go on kitesurf adventures and amazing parties!</p>
                <p className='hosted_card_last'>
                  <span>
                    <img className='me-2' src={earth} alt="icon" style={{ maxWidth: '25px' }} />
                  </span>
                  <span className='hosted_card_last'><b>Top Destination</b></span>
                </p>
                <p className='hosted_card_last2'>Zanzibar & Waddensea!</p>
                <p className='hosted_card_last'>
                  <span>
                    <img className='me-2' src={at} alt="icon" style={{ maxWidth: '25px' }} />
                  </span>
                  <span><b>Top Destination</b></span>
                </p>
                <p className='hosted_card_last2'>4 years</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="hosted_card shadow text-center">
              <img src={img3} className='hosted_card_img' alt='card' width="100%" />
              <div className='hosted_card_body'>
                <p className='hosted_card_title'>Ellis Muiser</p>
                <p>Kitecamp Host</p>
                <p className='hosted_card_year'>2 years kite experience</p>
                <p >I will show you all the hidden gems of Zanzibar! Together we go on kitesurf adventures and amazing parties!</p>
                <p className='hosted_card_last'>
                  <span>
                    <img className='me-2' src={earth} alt="icon" style={{ maxWidth: '25px' }} />
                  </span>
                  <span className='hosted_card_last'><b>Top Destination</b></span>
                </p>
                <p className='hosted_card_last2'>Zanzibar & Waddensea!</p>
                <p className='hosted_card_last'>
                  <span>
                    <img className='me-2' src={at} alt="icon" style={{ maxWidth: '25px' }} />
                  </span>
                  <span><b>Top Destination</b></span>
                </p>
                <p className='hosted_card_last2'>4 years</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="hosted_card shadow text-center">
              <img src={img4} className='hosted_card_img' alt='card' width="100%" />
              <div className='hosted_card_body'>
                <p className='hosted_card_title'>Ellis Muiser</p>
                <p>Kitecamp Host</p>
                <p className='hosted_card_year'>2 years kite experience</p>
                <p >I will show you all the hidden gems of Zanzibar! Together we go on kitesurf adventures and amazing parties!</p>
                <p className='hosted_card_last'>
                  <span>
                    <img className='me-2' src={earth} alt="icon" style={{ maxWidth: '25px' }} />
                  </span>
                  <span className='hosted_card_last'><b>Top Destination</b></span>
                </p>
                <p className='hosted_card_last2'>Zanzibar & Waddensea!</p>
                <p className='hosted_card_last'>
                  <span>
                    <img className='me-2' src={at} alt="icon" style={{ maxWidth: '25px' }} />
                  </span>
                  <span><b>Top Destination</b></span>
                </p>
                <p className='hosted_card_last2'>4 years</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hosted