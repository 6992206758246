import React from 'react'

const Blog = () => {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <p>This is blog </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog
