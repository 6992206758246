import img1 from '../../Images/slider/slider1.jpg'; 
import img2 from '../../Images/slider/slider2.jpg'; 
import img3 from '../../Images/slider/slider3.jpg'; 
import img4 from '../../Images/slider/slider4.jpeg'; 
import img5 from '../../Images/slider/slider5.jpg'; 
import img6 from '../../Images/slider/slider6.jpg'; 
import img7 from '../../Images/slider/slider7.jpg'; 
import img8 from '../../Images/slider/slider8.jpg'; 
import img9 from '../../Images/slider/slider9.jpg'; 
import img10 from '../../Images/slider/slider10.jpg'; 

export const Carddata = [
    {
        id: "1",
        img: img1,
        title: {
            en: 'Dhakla',
            nl: 'Dhakla', // Dutch
            hi: 'ढकला', // Hindi
        },
    },
    {
        id: "2",
        img: img2,
        title: {
            en: 'Vanlife',
            nl: 'Vanlife', // Dutch
            hi: 'वैनलाइफ', // Hindi
        },
    },
    {
        id: "3",
        img: img3,
        title: {
            en: 'tv-tas downwinder',
            nl: 'tv-tas downwinder', // Dutch
            hi: 'टीवी-तस डाउनविंडर', // Hindi
        },
    },
    {
        id: "4",
        img: img4,
        title: {
            en: 'Sky high cape Town',
            nl: 'Kaapstad met hoge luchten', // Dutch
            hi: 'स्काई हाई केप टाउन', // Hindi
        },
    },
    {
        id: "5",
        img: img5,
        title: {
            en: 'sail & Kite',
            nl: 'zeil & vlieger', // Dutch
            hi: 'सेल और काइट', // Hindi
        },
    },
    {
        id: "6",
        img: img6,
        title: {
            en: 'kite Mega trifa',
            nl: 'vlieger Mega trifa', // Dutch
            hi: 'काइट मेगा ट्रिफा', // Hindi
        },
    },
    {
        id: "7",
        img: img7,
        title: {
            en: 'Brazil',
            nl: 'Brazilië', // Dutch
            hi: 'ब्राज़ील', // Hindi
        },
    },
    {
        id: "8",
        img: img8,
        title: {
            en: 'Zanzibar',
            nl: 'Zanzibar', // Dutch
            hi: 'ज़ांज़ीबार', // Hindi
        },
    },
    {
        id: "9",
        img: img9,
        title: {
            en: 'Sicily',
            nl: 'Sicilië', // Dutch
            hi: 'सिसिली', // Hindi
        },
    },
    {
        id: "10",
        img: img10,
        title: {
            en: '',  // No title provided
            nl: '',  // No title provided
            hi: '',  // No title provided
        },
    },
];

export const Carddata_head_section = [
    {
        title: {
            en: "Your choice - which camp is your flavour?",
            nl: "Jouw keuze - welk kamp is jouw smaak?", // Dutch
            hi: "आपकी पसंद - कौन सा कैंप आपकी पसंद है?", // Hindi
        },
        subtitle: {
            en: "Kiteactive",
            nl: "Kiteactive", // Dutch
            hi: "काइटएक्टिव", // Hindi
        },
        subtitle1: {
            en: "- our most popular camps 2024!",
            nl: "- onze meest populaire kampen 2024!", // Dutch
            hi: "- हमारे सबसे लोकप्रिय कैंप 2024!", // Hindi
        }
    }
];

